import axios from 'axios'
import { useAuthStore } from '@/core-auth/store'

const baseURL = '/api/v1'
const axiosApi = axios.create({
  baseURL,
  headers: {},
})

axiosApi.interceptors.request.use(function (config) {
  const authStore = useAuthStore ? useAuthStore() : null
  if (authStore && authStore.authToken) {
    config.headers.Authorization = `Bearer ${authStore.authToken}`
  }
  return config
})

// TODO interceptor for reconnect / error handling
axiosApi.interceptors.response.use(
  function (response) {
    return response.data
  },
  async function (error) {
    // console.error(error)
    return Promise.reject(error)
  }
)

export default axiosApi
