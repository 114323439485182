import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/core-auth/store'

function beforeEnter(to, from, next) {
  const authStore = useAuthStore()

  if (!authStore.authToken) {
    authStore
      .refresh()
      .then(() => {
        next()
      })
      .catch((error) => {
        if (!authStore.authToken) {
          next('/auth/login?redirect=' + to.href)
        } else {
          next()
        }
      })
  } else {
    next()
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'AuthLogin',
    alias: ['/'],
    component: () =>
      import(/* webpackChunkName: "auth-login" */ '@/views/AuthLogin.vue'),
  },
  {
    path: '/auth/logout',
    name: 'AuthLogout',
    component: () =>
      import(/* webpackChunkName: "auth-logout" */ '@/views/AuthLogout.vue'),
  },
  {
    path: '/auth/forgot-password',
    name: 'AuthForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "auth-forgot-password" */ '@/views/AuthForgotPassword.vue'
      ),
  },
  {
    path: '/auth/reset-password',
    name: 'AuthResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "auth-reset-password" */ '@/views/AuthResetPassword.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles',
    name: 'VehiclesAll',
    component: () =>
      import(/* webpackChunkName: "vehicles" */ '@/views/VehiclesAll.vue'),
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/basedata',
    name: 'VehicleBasedataView',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-basedata-view" */ '@/views/VehicleBasedataView.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/location',
    name: 'VehicleLocationView',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-location-view" */ '@/views/VehicleLocationView.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/financings',
    name: 'VehicleFinancingView',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-financing-view" */ '@/views/VehicleFinancingView.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/pricing',
    name: 'VehiclePricingView',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-pricing-view" */ '@/views/VehiclePricingView.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/create',
    name: 'VehicleCreate',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-create" */ '@/views/VehicleCreate.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/create/:vehicleId',
    name: 'VehicleCreateBasedata',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-create-basedata" */ '@/views/VehicleCreateBasedata.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/financings/create',
    name: 'VehicleFinancingCreate',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-financings-create" */ '@/views/VehicleFinancingCreate.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/basedata/edit',
    name: 'VehicleBasedataEdit',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-basedata-edit" */ '@/views/VehicleBasedataEdit.vue'
      ),
  },
  // {
  //   beforeEnter,
  //   path: '/vehicles/:vehicleId/financings/add',
  //   name: 'VehicleFinancingAdd',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "vehicle-financing-add" */ '@/views/VehicleFinancingAdd.vue'
  //     ),
  // },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/financings/:financingId',
    name: 'VehicleFinancingEdit',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-financing-edit" */ '@/views/VehicleFinancingEdit.vue'
      ),
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/pricing/:templateId',
    // alias: ['/vehicles/:vehicleId/pricing/:templateId/print'],
    name: 'VehiclePricingEdit',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-pricing-edit" */ '@/views/VehiclePricingEdit.vue'
      ),
  },
  {
    beforeEnter,
    path: '/workshop',
    name: 'Workshop',
    component: () =>
      import(
        /* webpackChunkName: "vehicles-workshop" */ '@/views/VehiclesWorkshop.vue'
      ),
  },
  {
    beforeEnter,
    path: '/workshop-tasks',
    name: 'WorkshopTasksView',
    component: () =>
      import(
        /* webpackChunkName: "workshop-tasks" */ '@/views/WorkshopTasksView.vue'
      ),
    props: {
      onlyMyTasks: false,
    },
    children: [
      {
        name: 'WorkshopTasksEdit',
        path: '/workshop-tasks/:workshopTaskId',
        component: () =>
          import(
            /* webpackChunkName: "workshop-tasks-edit" */ '@/views/VehicleWorkshopTaskEdit.vue'
          ),
      },
    ],
  },
  {
    beforeEnter,
    path: '/my-workshop-tasks',
    name: 'MyWorkshopTasksView',
    component: () =>
      import(
        /* webpackChunkName: "workshop-tasks" */ '@/views/WorkshopTasksView.vue'
      ),
    props: {
      onlyMyTasks: true,
    },
    children: [
      {
        name: 'MyWorkshopTasksEdit',
        path: '/my-workshop-tasks/:workshopTaskId',
        component: () =>
          import(
            /* webpackChunkName: "workshop-tasks-edit" */ '@/views/VehicleWorkshopTaskEdit.vue'
          ),
      },
    ],
  },
  {
    beforeEnter,
    path: '/vehicles/:vehicleId/workshop-tasks',
    name: 'VehicleWorkshopTaskView',
    component: () =>
      import(
        /* webpackChunkName: "vehicle-workshop-task-view" */ '@/views/VehicleWorkshopTaskView.vue'
      ),
    children: [
      {
        name: 'VehicleWorkshopTaskCreate',
        path: '/vehicles/:vehicleId/workshop-tasks/create',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-workshop-task-create" */ '@/views/VehicleWorkshopTaskCreate.vue'
          ),
      },
      {
        name: 'VehicleWorkshopTaskEdit',
        path: '/vehicles/:vehicleId/workshop-tasks/:workshopTaskId',
        component: () =>
          import(
            /* webpackChunkName: "vehicle-workshop-task" */ '@/views/VehicleWorkshopTaskEdit.vue'
          ),
      },
    ],
  },
  {
    beforeEnter,
    path: '/eps-gateways',
    name: 'EpsGatewaysView',
    component: () =>
      import(
        /* webpackChunkName: "eps-gateways" */ '@/views/EpsGatewaysView.vue'
      ),
  },
  {
    beforeEnter,
    path: '/eps-tags',
    name: 'EpsTagsView',
    component: () =>
      import(/* webpackChunkName: "eps-tags" */ '@/views/EpsTagsView.vue'),
  },
  {
    beforeEnter,
    path: '/eps-matching',
    name: 'EpsMatchingView',
    component: () =>
      import(
        /* webpackChunkName: "eps-matching" */ '@/views/EpsMatchingView.vue'
      ),
  },
  {
    beforeEnter,
    path: '/user-profile',
    name: 'UserProfileView',
    component: () =>
        import(
            /* webpackChunkName: "user-profile" */ '@/views/UserProfileView.vue'
            ),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  },
})

export default router
