import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import './assets/styles/tailwind.css'
import {i18n, } from './i18n'
import { createHead } from '@vueuse/head'
import './registerServiceWorker'
import { createPinia } from 'pinia'
import VueGoogleMaps from '@fawmi/vue-google-maps'

// const i18n = createI18n({
//   legacy: false,
//   locale: 'en-US',
//   fallbackLocale: 'de',
//   messages,
// })

const app = createApp(App)
  .use(i18n)
  .use(createPinia())
  .use(router)
  .use(createHead())

// if (process.env.VUE_APP_GOOGLE_API_KEY) {
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCUCAawlc48KkQgZqOGHJncTPo07lUZ28I',
    // key: process.env.VUE_APP_GOOGLE_API_KEY,
  },
})
// }

app.mount('#app')
