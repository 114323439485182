import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import api from './api'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      authToken: null,
      me: null,
      apiToken: null
    }
  },
  getters: {},
  actions: {
    login({ username, password }) {
      return api.login({ username, password }).then((response: any) => {
        this.setAuthToken(response.access)
        this.setRefreshToken(response.refresh)
      })
    },
    refresh() {
      const refreshToken = Cookies.get('refreshToken')
      if (!refreshToken) {
        return Promise.reject()
      }
      return api.refresh(refreshToken).then((response: any) => {
        this.setAuthToken(response.access)
      })
    },
    forgotPassword(username) {
      return api.forgotPassword(username)
    },
    resetPassword(password: string, token: string) {
      return api.resetPassword({ token, password })
    },
    logout() {
      this.authToken = null
      Cookies.remove('refreshToken')
    },
    setAuthToken(authToken) {
      this.authToken = authToken
    },
    setRefreshToken(refreshToken) {
      Cookies.set('refreshToken', refreshToken)
    },
    getMe() {
      if (this.me) {
        return Promise.resolve(this.me)
      }
      return api.getMe().then((response) => {
        this.me = response
        return this.me
      })
    },
    updateMe(data){
      return api.updateActiveUser(data).then((response) => {
        this.me = response
        return this.me
      })
    },
    regenerateImportApiToken(data){
      return api.regenerateImportApiToken(data).then((response) => {
        this.apiToken = response
        return this.apiToken
      })
    },
    getImportApiToken(data){
      return api.getImportApiToken(data).then((response) => {
        this.apiToken = response
        return this.apiToken
      })
    },
    updateTenant(data){
      return api.updateTenant(data).then((response) => {
        return response
      })
    }
  },
})
