import axios from '@/axios'

const route = '/auth'

const login = async ({ username, password }) => {
  return axios({
    url: route + '/login/',
    method: 'post',
    data: { username, password },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const refresh = async (refresh) => {
  return axios({
    url: route + '/refresh/',
    method: 'post',
    data: { refresh },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const forgotPassword = async (username) => {
  return axios({
    url: route + '/forgot-password/',
    method: 'post',
    data: { username },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const resetPassword = async ({ token, password }) => {
  return axios({
    url: route + '/reset-password/',
    method: 'post',
    data: { token, password },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const getMe = async () => {
  return axios({
    url: route + '/me/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const updateActiveUser = async (data) => {
  return axios({
    url: `/users/${data.id}/`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
    },
    data
  })
}

const getImportApiToken = async (data) => {
  return axios({
    url: `/users/${data.id}/auth_token/`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    data
  })
}

const regenerateImportApiToken = async (user) => {
  return axios({
    url: `/users/${user.id}/renew_token/`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {user: user.id}
  })
}

const updateTenant = async (data) => {

  return axios({
    url: `/tenants/${data.id}/`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  })
}


export default {
  login,
  refresh,
  forgotPassword,
  resetPassword,
  getMe,
  updateActiveUser,
  getImportApiToken,
  regenerateImportApiToken,
  updateTenant
}
