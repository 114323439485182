import en from './locales/en-us-lang.json'
import {createI18n} from "vue-i18n";
import {nextTick} from 'vue'

export const i18n = await setupI18n()

export async function setupI18n(options = {locale: 'en-US', fallbackLocale: 'de', legacy: false, messages: {en}}) {
    const i18n = createI18n(options)
    setI18nLanguage(i18n, options.locale);

    // use local storage to store language
    return i18n
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    document.querySelector('html').setAttribute('lang', locale)
}


export async function loadLocaleMessages(locale) {
    // load locale messages with dynamic import
    const messages = await import(
        /* webpackChunkName: "locale-[request]" */ `@/locales/${locale.toLowerCase()}-lang.json`
        )
    i18n.global.setLocaleMessage(locale, messages.default)
    setI18nLanguage(i18n, locale)
    return nextTick()
}
